<template>
  <div class="ma-md-3">
    <v-card>
      <v-card-actions class="pa-3">
        <v-row align="center">
          <v-col cols="4" md="auto">
            <v-btn text v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-btn>
          </v-col>
          <v-col cols="4" md="auto">
            <v-btn class="mr-4" outlined @click="setToday">
              Heute
            </v-btn>
          </v-col>
          <v-col cols="3" md="auto">
            <v-btn outlined fab small  @click="prev">
              <v-icon >mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab class="ml-2" small outlined @click="next">
              <v-icon >mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <!-- historyBack Button -->
          <v-col v-if="admin"  cols="6" md="auto">
            <v-tooltip bottom v-if="this.historyHandler.currentIndex !== -1">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                      outlined
                      @click="back"
                  >
                    <!-- :disabled="this.historyHandler.currentIndex <= 0" -->
                    <v-icon>mdi-step-backward</v-icon>
                    Schritt zurück
                  </v-btn>
                </div>
              </template>
              <span>Schritt zurück</span>
            </v-tooltip>
            <v-btn
                v-else
                text
                disabled
            >
              <v-icon>mdi-arrow-left-bold</v-icon>
            </v-btn>
          </v-col>
          <!-- Wochenplan einfüge button -->
          <v-col v-if="admin"  cols="6" md="auto">
            <v-btn outlined @click="addEvents()">Wochenplan Einfügen</v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-switch class="pt-2" v-if="getPermission" v-model="mpu" label="MPU" text @click="mpuSwitch()"></v-switch>
          </v-col>
          <v-col v-if="admin"  cols="12" md="auto">
            <v-cascader v-model="permission" :items="permissions" @input="updatePermissions()" v-on:clear="clearPermission()" :returnObject="true" clearable childrenKey="childPermissions" item-value="value" item-text="name" label="Servicearten" />
          </v-col>
          <!-- historyForward Button -->
          <!--
          <div>
            <v-tooltip bottom v-if="this.historyHandler.history.length > 0 && this.historyHandler.currentIndex < this.historyHandler.history.length - 1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    text
                    @click="forward"
                >
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
              </template>
              <span>Schritt nach vorne</span>
            </v-tooltip>
            <v-btn
                v-else
                small
                text
                disabled
            >
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>

          </div>
          -->
          <v-col cols="12" md="auto">
            <v-autocomplete
                v-model="filter"
                class="pt-5"
                :items="creators"
                :search-input.sync="searchCreator"
                clearable
                @click:clear="clearLawyer()"
                color="primary"
                hide-no-data
                hide-selected
                item-text="username"
                item-value="username"
                placeholder="Filter"
                return-object
                @input="applyFilter"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="auto">
            <v-menu bottom right class="ma-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list >
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Tag</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Woche</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Monat</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text>
        <v-sheet :height="$vuetify.breakpoint.mdAndUp ? '75vh' : '60vh'">
          <v-calendar
              ref="calendar"
              v-model="focus"
              :event-color="getEventColor"
              :events="events"
              :type="type"
              :weekdays="weekdays"
              color="primary"
              event-overlap-mode="column"
              @change="updateRange"
              @click:date="viewDay"
              @click:event="showEvent"
              @click:more="viewDay"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mouseleave.native="cancelDrag"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
              :first-interval="mpu ? 8 : 0"
              :interval-count="mpu ? 13 : 24"
          >
            <template v-slot:event="{ event, timed, eventSummary }">
              <div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
              <div
                  v-if="timed"
                  class="v-event-drag-bottom"
                  @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-card-text>
      <!-- Plan calendar -->
      <v-dialog
          v-model="addWeekAppointmentDialog"
          fullscreen>
        <template-calender v-bind:dialog="addWeekAppointmentDialog" v-on:closeDialog="closeDialog()"/>
      </v-dialog>
      <!-- Name/Time/Date edit -->
      <v-dialog
          v-model="nameEditDialog"
          flat
          width="20vw"
      >
        <v-card>
          <v-card-title>
            Termin bearbeiten
          </v-card-title>
          <v-card-text>
            <v-autocomplete
                v-if="admin"
                v-model="creator"
                :items="creators"
                :label="inputLables['creator']"
                :placeholder="inputLables['creatorSearch']"
                :search-input.sync="searchCreator"
                clearable
                color="primary"
                hide-no-data
                hide-selected
                item-text="username"
                item-value="username"
                return-object
            ></v-autocomplete>
            <v-menu
                v-if="selectedEvent.allDay === false"
                ref="menu1"
                v-model="clockMenu1"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="clockFrom"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="clockFrom"
                    v-bind="attrs"
                    v-on="on"
                    label="Von"
                    prepend-icon="mdi-clock"
                    readonly
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="clockMenu1"
                  v-model="clockFrom"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menu1.save(clockFrom)"
              ></v-time-picker>
            </v-menu>
            <v-menu
                v-if="selectedEvent.allDay === false"
                ref="menu2"
                v-model="clockMenu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="clockTo"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="clockTo"
                    v-bind="attrs"
                    v-on="on"
                    label="Bis"
                    prepend-icon="mdi-clock"
                    readonly
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="clockMenu2"
                  v-model="clockTo"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menu2.save(clockTo)"
              ></v-time-picker>
            </v-menu>
            <v-menu
                v-if="selectedEvent.allDay === true"
                ref="dateMenu3"
                v-model="dateMenu3"
                :close-on-content-click="false"
                :return-value.sync="dateFrom1"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFrom1"
                    v-bind="attrs"
                    v-on="on"
                    label="Von Datum"
                    prepend-icon="mdi-calendar-arrow-right"
                    readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom1" no-title scrollable @change="$refs.dateMenu3.save(dateFrom1)">
              </v-date-picker>
            </v-menu>
            <v-menu
                v-if="selectedEvent.allDay === true"
                ref="dateMenu4"
                v-model="dateMenu4"
                :close-on-content-click="false"
                :return-value.sync="dateTo1"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateTo1"
                    v-bind="attrs"
                    v-on="on"
                    label="Bis Datum"
                    prepend-icon="mdi-calendar-arrow-left"
                    readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateTo1" no-title scrollable @change="$refs.dateMenu4.save(dateTo1)">
              </v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="error" text @click="nameEditDialog = false">
              Cancel
            </v-btn>
            <v-btn color="success" text @click="changeName(selectedEvent)">
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Absent dialog -->
      <v-dialog
          v-model="absentDialog"
          color="grey lighten-4"
          flat
          :width="$vuetify.breakpoint.mdAndUp ? '20vw' : '90vw'"
      >
        <v-card>
          <v-card-title>
            {{ admin ? 'Rückruf hinzufügen' : 'Abwesenheit hinzufügen' }}
          </v-card-title>
          <v-card-text>
            <v-menu
                ref="dateMenu1"
                v-model="dateMenu1"
                :close-on-content-click="false"
                :return-value.sync="dateFrom"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFrom"
                    v-bind="attrs"
                    v-on="on"
                    label="Von Datum"
                    prepend-icon="mdi-calendar-arrow-right"
                    readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom" no-title scrollable @change="$refs.dateMenu1.save(dateFrom)">
              </v-date-picker>
            </v-menu>
            <v-menu
                ref="dateMenu2"
                v-model="dateMenu2"
                :close-on-content-click="false"
                :return-value.sync="dateTo"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateTo"
                    v-bind="attrs"
                    v-on="on"
                    label="Bis Datum"
                    prepend-icon="mdi-calendar-arrow-left"
                    readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateTo" no-title scrollable @change="$refs.dateMenu2.save(dateTo)">
              </v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="error" text @click="absentDialog = false">
              Cancel
            </v-btn>
            <v-btn :disabled="dateTo === '' ||  dateFrom === '' || dateTo < dateFrom" color="success"
                   text @click="absentSave()">
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-menu
        v-model="selectedOpen"
        :activator="selectedElement"
        :close-on-content-click="false"
        offset-x
    >
      <v-card
          flat
          min-width="350px"
      >
        <v-toolbar
            :color="selectedEvent.color"
            dark
        >
          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="admin || selectedEvent.name === getUsername()" icon @click="openEditDialog()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="admin || selectedEvent.name === getUsername()" icon
                 @click="deleteAppointment(selectedEvent.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-if="selectedEvent.start" :value="selectedEvent.start" disabled
                        prepend-icon="mdi-calendar-arrow-right"
                        readonly></v-text-field>
          <v-text-field v-if="selectedEvent.end" :value="selectedEvent.end" disabled
                        prepend-icon="mdi-calendar-arrow-left"
                        readonly></v-text-field>
        </v-card-text>
        <!-- ToDo move to show appointments to only allow linking when limit not reached-->
        <v-card-text v-if="selectedEvent.appointmentType === 'mpu'">
          <show-appointments-by-appointment-id v-bind:appointmentId="selectedEvent.id" />
        </v-card-text>
        <appointment-linking-actions v-if="selectedEvent.appointmentType === 'mpu'" v-bind:mpu="mpu" v-bind:selectedEvent="selectedEvent" />
        <div v-else >
          <v-card-title>Versicherungsberechtigungen</v-card-title>
          <div v-if="creatorsMap[selectedEvent.name]">
            <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].advocardBusinessPermissions.length > 0">
              <v-card-title>Advocard Business Berechtigungen</v-card-title>
              <v-row class="mb-2 mx-2">
                <v-col v-for="perm in creatorsMap[selectedEvent.name].advocardBusinessPermissions" v-bind:key="perm">
                  <v-chip>{{ perm }}</v-chip>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="ma-2" outlined color="primary" v-if="creatorsMap[selectedEvent.name].advocardInternationalPermissions.length > 0">
              <v-card-title>Advocard International Berechtigungen</v-card-title>
              <v-row class="mb-2 mx-2">
                <v-col v-for="perm in creatorsMap[selectedEvent.name].advocardInternationalPermissions" v-bind:key="perm">
                  <v-chip>{{ perm }}</v-chip>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].advocardPrivatePermissions.length > 0">
              <v-card-title>Advocard Private Berechtigungen</v-card-title>
              <v-row class="mb-2 mx-2">
                <v-col v-for="perm in creatorsMap[selectedEvent.name].advocardPrivatePermissions" v-bind:key="perm">
                  <v-chip>{{ perm }}</v-chip>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].aragPermissions.length > 0">
              <v-card-title>ARAG Berechtigungen</v-card-title>
              <v-row class="mb-2 mx-2">
                <v-col v-for="perm in creatorsMap[selectedEvent.name].aragPermissions" v-bind:key="perm">
                  <v-chip>{{ perm }}</v-chip>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].rolandPermissions.length > 0">
              <v-card-title>Roland Berechtigungen</v-card-title>
              <v-row class="mb-2 mx-2">
                <v-col v-for="perm in creatorsMap[selectedEvent.name].rolandPermissions" v-bind:key="perm">
                  <v-chip>{{ perm }}</v-chip>
                </v-col>
              </v-row>
            </v-card>

          </div>
        </div>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :color="mpu ? 'blue' : 'secondary'"
              text
              @click="selectedOpen = false"
          >
            schliessen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-btn
        v-if="admin && !moderator"
        absolute
        bottom
        color="primary"
        dark
        fab
        right
        style="margin-bottom: 25px"
        @click="addWeekAppointmentDialog = true"
    >
      <v-icon>mdi-calendar</v-icon>
    </v-btn>
    <v-btn
        :style="admin ?  'margin-bottom: 90px' : 'margin-bottom: 25px'"
        absolute
        bottom
        color="primary"
        dark
        fab
        right
        @click="absentDialog = true"
    >
      <v-icon>{{ admin ? 'mdi-phone' : 'mdi-run-fast' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {HistoryEntity, HistoryHandler} from "@/utils/historyHandler";
import {
  getAppointmentsURL,
  getAppointmentURL,
  getLawyersURL,
  getTemplateAppointmentsURL,
  requestMode
} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "../plugins/i18n";
import TemplateCalender from "@/components/Calendar/TemplateCalendar";
import reactiveStorage from "@/plugins/reactiveStorage";
import VCascader from "@/components/VCascader";
import ShowAppointmentsByAppointmentId from "@/components/MPU/ShowAppointmentsByAppointmentId";
import AppointmentLinkingActions from "@/components/Calendar/AppointmentLinkingActions";
import {permissionConst} from "@/plugins/permission";

export default {
  name: "Calendar",
  components: {
    AppointmentLinkingActions,
    ShowAppointmentsByAppointmentId, TemplateCalender, VCascader },
  data: () => ({
    historyHandler: new HistoryHandler(),
    beforeHistoryHandler: {},
    backHint: true,
    mpu: false,
    forwardHint: true,
    focus: '',
    weekFocus: '',
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    weekday: [
      {name: "MONDAY", date: "1999-06-07", ger: 'Montag'},
      {name: "TUESDAY", date: "1999-06-08", ger: 'Dienstag'},
      {name: "WEDNESDAY", date: "1999-06-09", ger: 'Mittwoch'},
      {name: "THURSDAY", date: "1999-06-10", ger: 'Donnerstag'},
      {name: "FRIDAY", date: "1999-06-11", ger: 'Freitag'},
      {name: "SATURDAY", date: "1999-06-12", ger: 'Samstag'},
      {name: "SUNDAY", date: "1999-06-13", ger: 'Sonntag'},
    ],
    admin: reactiveStorage.user.roles.Admin,
    moderator: reactiveStorage.user.roles.Moderator,
    nameEditDialog: false,
    addFullDayWeekAppointmentDialog: false,
    namePlanEditDialog: false,
    type: 'week',
    weekType: 'week',
    typeToLabel: {
      month: 'Monat',
      week: 'Woche',
      day: 'Tag',
    },
    filter: undefined,
    unfilteredPlanEvents: [],
    selectedEvent: {},
    searchCreator: null,
    creators: [],
    creatorsMap: {},
    isLoadingCreator: false,
    creator: undefined,
    planCreator: undefined,
    selectedElement: null,
    selectedOpen: false,
    events: [],
    weekEvents: [],
    allEvents: [],
    ownEvents: [],
    addWeekAppointmentDialog: false,
    absentDialog: false,
    colors: ['grey darken-2', 'secondary'],
    clockFrom: "",
    clockMenu1: "",
    clockMenu2: "",
    dateMenu1: "",
    dateMenu2: "",
    dateMenu3: "",
    dateMenu4: "",
    dateFrom: "",
    dateFrom1: "",
    dateTo: "",
    dateTo1: "",
    clockTo: "",
    selectedDay: "",
    days: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
    filteredByLawyers: false,
    filteredByPermissions: false,
    permission: null,
    //TODO build this same as AdminPanel to only have one point to edit
    permissions: permissionConst,

  }),
  mounted() {
    if (this.$route.query.mpu) {
      this.mpu = this.$route.query.mpu;
    }

    this.$refs.calendar.checkChange()
  },
  methods: {
    getUsername(){
      return reactiveStorage.user.Username;
    },
    clearLawyer(){
      this.filteredByLawyers = false;
      if (!this.filteredByPermissions){
        this.events = this.unfilteredEvents;
      }
      if (this.filteredByPermissions){
        this.events = this.unfilteredEvents;
        this.updatePermissions()
      }
    },
    clearPermission(){
      this.filteredByPermissions = false;
      if (!this.filteredByLawyers){
        this.events = this.unfilteredEvents;
      }
      if (this.filteredByLawyers){
        this.events = this.unfilteredEvents;
        this.applyFilter()
      }
    },
    updatePermissions(){
      if (this.permission){
        let value = this.permission.split("/")
        if (value[1]){
          if(this.filteredByLawyers && !this.filteredByPermissions){
            this.filteredByPermissions = true;
            this.events = this.events.filter(event => {
              if (this.creatorsMap[event.name]){
                if (this.creatorsMap[event.name][value[0]])
                  return this.creatorsMap[event.name][value[0]].filter(perm => {
                    return perm === value[1]
                  }).length > 0
              } else {
                return false;
              }
            })
          }  else if (this.filteredByPermissions && this.filteredByLawyers){
            this.events = this.unfilteredEvents.filter(event => {
              if (this.creatorsMap[event.name]){
                if (this.creatorsMap[event.name][value[0]])
                  return this.creatorsMap[event.name][value[0]].filter(perm => {
                    return perm === value[1]
                  }).length > 0
              } else {
                return false;
              }
            })
            this.events = this.events.filter(event => event.name === this.filter.username)
          }else {
            this.filteredByPermissions = true;
            this.events = this.unfilteredEvents.filter(event => {
              if (this.creatorsMap[event.name]){
                if (this.creatorsMap[event.name][value[0]])
                  return this.creatorsMap[event.name][value[0]].filter(perm => {
                    return perm === value[1]
                  }).length > 0
              } else {
                return false;
              }
            })
          }
        }
      }
    },
    closeDialog(){
      this.addWeekAppointmentDialog = !this.addWeekAppointmentDialog
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    wait(ms) {
      const start = new Date().getTime();
      let end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    async back() {
      if (this.historyHandler.currentIndex >= 0) {
        await this.historyHandler.back()
        let start;
        let end;
        await this.updateRange({start, end})
      }
    },
    async forward() {
      if (this.historyHandler.history.length > 0 && this.historyHandler.currentIndex < this.historyHandler.history.length - 1) {
        await this.historyHandler.forward()
        let start;
        let end;
        await this.updateRange({start, end})
      }
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async updateRange({start, end}) {
      // console.log(start, end)
      this.events = []
      if (start && end) {
        this.min = start.date
        this.max = end.date
      }

      await this.getEvents(this.min, this.max, false)
      if (!this.admin) {
        await this.getEvents(this.min, this.max, true)
      }
      this.allEvents.forEach(appointment => {

        if (appointment.allDay === true) {
          appointment.end = appointment.end.substr(0, 10)
          appointment.start = appointment.start.substr(0, 10)
          appointment.allDay = true
          if (appointment.appointmentType === "absent") {
            appointment.color = "error"
          } else {
            appointment.color = "accent"
          }
        } else {
          appointment.color = "accent"
        }
        if (!this.admin) {
          this.ownEvents.forEach(ownAppointment => {
            if (ownAppointment.id === appointment.id) {
              appointment.color = "primary";
            }
          })
        }
        if (appointment.name === "Zu benennen") {
          appointment.color = "error"
        }
        if (appointment.appointmentType === "mpu") {
          appointment.color = "blue"
        }
      })
      this.events = this.allEvents
      this.unfilteredEvents = this.events
      this.applyFilter()
      this.updatePermissions()
    },
    applyFilter() {
      if (this.filter) {
        if(this.filteredByPermissions && !this.filteredByLawyers){
          this.filteredByLawyers = true;
          this.events = this.events.filter(event => event.name === this.filter.username)
        } else if (this.filteredByPermissions && this.filteredByLawyers){
          let value = this.permission.split("/")
          this.events = this.unfilteredEvents.filter(event => {
            if (this.creatorsMap[event.name]){
              if (this.creatorsMap[event.name][value[0]])
                return this.creatorsMap[event.name][value[0]].filter(perm => {
                  return perm === value[1]
                }).length > 0
            } else {
              return false;
            }
          })
          this.events = this.events.filter(event => event.name === this.filter.username)
        }
        else {
          this.filteredByLawyers = true;
          this.events = this.unfilteredEvents.filter(event => event.name === this.filter.username)
        }
      }
    },
    mpuSwitch() {
      let start = {}
      start.date = this.min
      let end = {}
      end.date = this.max
      this.updateRange({start, end})
      // this.getAllCreators()
    },
    async getEvents(minDate, maxDate, own) {
      await this.getAllCreators();
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }
      Date.prototype.removeDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() - days);
        return date;
      }

      let parameter = {
        'startFrom': new Date(minDate).removeDays(10).toISOString().substr(0, 10),
        'startTo': new Date(maxDate).addDays(10).toISOString().substr(0, 10),
      }
      //  if Session isValid
      await sessionHandler();
      await this.$http
          .get(
              getAppointmentsURL(own)
              , {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              if (own) {
                if (this.mpu) {
                  this.ownEvents = response.data.filter(appointment => appointment.appointmentType === "mpu");
                } else {
                  this.ownEvents =  response.data.filter(appointment => appointment.appointmentType !== "mpu");
                }
                this.$forceUpdate();
                if (this.ownEvents.length === 0) {
                  showAlert(i18n.t('warning.empty'), "warning");
                }
              } else {
                if (this.mpu) {
                  this.allEvents = response.data.filter(appointment => appointment.appointmentType === "mpu" );
                } else {
                  this.allEvents = response.data.filter(appointment => appointment.appointmentType !== "mpu");
                }
                this.$forceUpdate();
                if (this.allEvents.length === 0) {
                  showAlert(i18n.t('warning.empty'), "warning");
                }
              }

            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE1" + own, "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2" + own, "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
          })
    },
    async getPlanEvents() {

      //  if Session isValid
      await sessionHandler();
      await this.$http
          .get(
              getTemplateAppointmentsURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {

              this.unfilteredPlanEvents = response.data
              if (this.unfilteredPlanEvents.length === 0) {
                showAlert(i18n.t('warning.empty'), "warning");
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE4", "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
          })
    },


    //Time conv
    roundTime(time, down = true) {
      const roundTo = 60 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },

    //Drag & drop
    startDrag({event, timed}) {
      if (event && timed) {
        this.beforeHistoryHandler.start = event.start
        this.beforeHistoryHandler.end = event.end
        this.beforeHistoryHandler.name = event.name
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },

    getDateString(dateToFormat) {
      Number.prototype.cleanForDate = function () {
        switch (true){
          case (this === 0):
            return "00"
          case (this < 10 && this !== 0):
            return "0" + this
          default:
            return this.toString()

        }
      }
      const month = new Date(dateToFormat).getMonth() + 1
      const date = new Date(dateToFormat).getFullYear() + "-" + month.cleanForDate() + "-" + new Date(dateToFormat).getDate().cleanForDate()
      const time = new Date(dateToFormat).getHours().cleanForDate() + ":" + new Date(dateToFormat).getMinutes().cleanForDate()
      return date + " " + time
    },

    async startTime(tms) {
      Date.prototype.addHours = function (hour) {
        this.setTime(this.getTime() + (hour * 60 * 60 * 1000));
        return this;
      }
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = new Date(this.dragEvent.start)
        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        this.createEvent = {
          start:  this.getDateString(this.createStart),
          end: this.getDateString(new Date(this.createStart).addHours(1)),
          name: this.admin ? 'Zu benennen' : this.$RStore.user.Username
        }
        //TODO Zu benennen Bugfix
        await this.createAppointment(this.createEvent.start, this.createEvent.end, this.createEvent.name, this.mpu ? "mpu" : "hotline", false)

        let color = {color: this.admin ? "error" : "primary", timed: true};
        this.createEvent = {...this.createEvent, ...color};
        this.events.push(this.createEvent)
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.beforeHistoryHandler.start = event.start
      this.beforeHistoryHandler.end = event.end
      this.beforeHistoryHandler.name = event.name
      this.createStart = new Date(event.start).getTime()
      this.extendOriginal = event.end
      this.updateAppointment(event.start, event.end, event.name, event.id, event.appointmentType, event.allDay)
    },
    mouseMove(tms) {
      Date.prototype.addHours = function (hour) {
        this.setTime(this.getTime() + (hour * 60 * 60 * 1000));
        return this;
      }
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime !== null) {
        if (this.admin || this.dragEvent.name === this.getUsername()) {
          const start = new Date(this.dragEvent.start)
          const end = new Date(this.dragEvent.end)
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration
          this.dragEvent.start = this.getDateString(newStart)
          this.dragEvent.end = this.getDateString(newEnd)
        }
      } else if (this.createEvent && this.createStart !== null) {
        if (this.admin || this.createEvent.name === this.getUsername()) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)
          this.createEvent.start = this.getDateString(min)
          this.createEvent.end = this.getDateString(max)
        }
      }
    },
    endDrag() {
      if (this.dragEvent && this.dragTime !== null) {
        this.updateAppointment(this.dragEvent.start, this.dragEvent.end, this.dragEvent.name, this.dragEvent.id, this.dragEvent.appointmentType, this.dragEvent.allDay)
      } else if (this.createEvent && this.createStart !== null) {
        if(this.createEvent.id) {
          this.updateAppointment(this.createEvent.start, this.createEvent.end, this.createEvent.name, this.createEvent.id, this.createEvent.appointmentType, this.createEvent.allDay)
        }
        }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },

    // add Management
    async createAppointment(startDate, endDate, name, appointmentType, allDay) {
      const formData = {
        "allDay": allDay,
        "start": startDate,
        "end": endDate,
        "name": name,
        "appointmentType": appointmentType,
      };

      await sessionHandler();

      await this.$http
          .post(
              getAppointmentURL(!this.admin),
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.createEvent = response.data;
              this.beforeHistoryHandler.start = response.data.start
              this.beforeHistoryHandler.end = response.data.end
              this.beforeHistoryHandler.name = response.data.name
              let historyEntity = new HistoryEntity(getAppointmentURL(!this.admin), "create", response.data)
              this.historyHandler.add(historyEntity)
              showAlert(i18n.t('success.form'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-cA1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-cA1", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    async createAppointments(appointments) {

      await sessionHandler();

      await this.$http
          .post(
              getAppointmentsURL(),
              appointments
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              let historyEntity = new HistoryEntity(getAppointmentsURL(), "create", response.data)
              this.historyHandler.add(historyEntity)
              showAlert(i18n.t('success.form'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-cA1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-cA1", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
      let start;
      let end;
      await this.updateRange({start, end})
    },
    async updateAppointment(startDate, endDate, name, id, appointmentType, allDay) {
      const formData = {
        "allDay": allDay,
        "start": this.getDateString(startDate),
        "end": this.getDateString(endDate),
        "name": name,
        "appointmentType": appointmentType,
      };

      await sessionHandler();

      await this.$http
          .put(
              getAppointmentURL(!this.admin) + "/" + id,
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {

              let historyEntity = new HistoryEntity(getAppointmentURL(!this.admin), "update", response.data, this.beforeHistoryHandler)
              this.historyHandler.add(historyEntity)
              showAlert(i18n.t('success.form'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-uA1", "error");
            }
          })
          .catch((error) => {
            if (!error.response) {
              showAlert(i18n.t('error.api.undefined') + "Calendar-uA2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    async deleteAppointment(id) {
      await sessionHandler();

      await this.$http
          .delete(
              getAppointmentURL(!this.admin) + "/" + id
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.selectedOpen = false
              const removeIndex = this.events.map(function (item) {
                return item.id;
              }).indexOf(id);
              let historyEntity = new HistoryEntity(getAppointmentURL(!this.admin), "delete", response.data, this.events[removeIndex])
              this.historyHandler.add(historyEntity)
              this.events.splice(removeIndex, 1);
              showAlert(i18n.t('success.form'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-C1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-C1", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },

    //addToRealCalendar
    async addEvents() {
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }
      this.unfilteredPlanEvents = []
      this.insertEvents = [];
      await this.getPlanEvents()
      if (this.unfilteredPlanEvents.length !== 0) {
        this.unfilteredPlanEvents.forEach((event) => {
          event.start = this.weekday[this.weekday.findIndex(weekday => weekday.name === event.dayOfWeek)].date + " " + event.start.substr(0, 5)
          event.end = this.weekday[this.weekday.findIndex(weekday => weekday.name === event.dayOfWeek)].date + " " + event.end.substr(0, 5)
          if (event.name !== 'Zu benennen') {
            //let color = {color: "success", timed: true};
            event.color = "success";
          }
        })
      }
      this.weekEvents = this.unfilteredPlanEvents
      this.weekEvents.forEach((event) => {
        let tempEvent = event
        switch (new Date(event.start).getDay()) {
          case 1:
            //Monday
            tempEvent.start = this.min + " " + tempEvent.start.substr(11, 5)
            tempEvent.end = this.min + " " + tempEvent.end.substr(11, 5)
            this.insertEvents.push(tempEvent)
            break;
          case 2:
            //Tuesday
            tempEvent.start = new Date(this.min).addDays(1).toISOString().substr(0, 10) + " " + tempEvent.start.substr(11, 5)
            tempEvent.end = new Date(this.min).addDays(1).toISOString().substr(0, 10) + " " + tempEvent.end.substr(11, 5)
            this.insertEvents.push(tempEvent)
            break;
          case 3:
            //Wednesday
            tempEvent.start = new Date(this.min).addDays(2).toISOString().substr(0, 10) + " " + tempEvent.start.substr(11, 5)
            tempEvent.end = new Date(this.min).addDays(2).toISOString().substr(0, 10) + " " + tempEvent.end.substr(11, 5)
            this.insertEvents.push(tempEvent)
            break;
          case 4:
            //Thursday
            tempEvent.start = new Date(this.min).addDays(3).toISOString().substr(0, 10) + " " + tempEvent.start.substr(11, 5)
            tempEvent.end = new Date(this.min).addDays(3).toISOString().substr(0, 10) + " " + tempEvent.end.substr(11, 5)
            this.insertEvents.push(tempEvent)
            break;
          case 5:
            //Friday
            tempEvent.start = new Date(this.min).addDays(4).toISOString().substr(0, 10) + " " + tempEvent.start.substr(11, 5)
            tempEvent.end = new Date(this.min).addDays(4).toISOString().substr(0, 10) + " " + tempEvent.end.substr(11, 5)
            this.insertEvents.push(tempEvent)
            break;
          case 6:
            //Saturday
            tempEvent.start = new Date(this.min).addDays(5).toISOString().substr(0, 10) + " " + tempEvent.start.substr(11, 5)
            tempEvent.end = new Date(this.min).addDays(5).toISOString().substr(0, 10) + " " + tempEvent.end.substr(11, 5)
            this.insertEvents.push(tempEvent)
            break;
          case 0:
            //Sunday
            tempEvent.start = new Date(this.min).addDays(6).toISOString().substr(0, 10) + " " + tempEvent.start.substr(11, 5)
            tempEvent.end = new Date(this.min).addDays(6).toISOString().substr(0, 10) + " " + tempEvent.end.substr(11, 5)
            this.insertEvents.push(tempEvent)
            break;
        }
      })
      await this.createAppointments(this.insertEvents)

    },

    //dialog
    lawyerArrayToMap(arr){
      // console.log(arr)
      arr.forEach(lawyer => {
        const username = lawyer.username
        let permissions = {
          advocardBusinessPermissions: [],
          advocardInternationalPermissions: [],
          advocardPrivatePermissions: [],
          aragPermissions: [],
          rolandPermissions: []
        };
       //  console.log(lawyer, username)

        Object.keys(lawyer.advocardBusinessPermissions).forEach((key) => {
          if ( lawyer.advocardBusinessPermissions[key] === true){
            permissions.advocardBusinessPermissions.push(key)
          }
        }) ;
        Object.keys(lawyer.advocardInternationalPermissions).forEach((key) => {
          if ( lawyer.advocardInternationalPermissions[key] === true){
            permissions.advocardInternationalPermissions.push(key)
          }
        });
        Object.keys(lawyer.advocardPrivatePermissions).forEach((key) => {
          if ( lawyer.advocardPrivatePermissions[key] === true){
            permissions.advocardPrivatePermissions.push(key)
          }
        });
        Object.keys(lawyer.aragPermissions).forEach((key) => {
          if ( lawyer.aragPermissions[key] === true){
            permissions.aragPermissions.push(key)
          }
        });
        Object.keys(lawyer.rolandPermissions).forEach((key) => {
          if ( lawyer.rolandPermissions[key] === true){
            permissions.rolandPermissions.push(key)
          }
        })

        this.creatorsMap[username] = permissions
      })
    },

    async getAllCreators() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .get(
              getLawyersURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              if (this.mpu) {
                this.creators = response.data.filter(lawyer => lawyer.mpuPermissions.MPU)
              } else {
                this.creators = response.data
              }
              this.lawyerArrayToMap(response.data);
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gAC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gAC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCreator = false;
          })
    },
    changeName(event) {
      if (event.allDay === false) {
        if (this.creator !== undefined) {
          this.updateAppointment(event.start.substr(0, 10) + " " + this.clockFrom, event.end.substr(0, 10) + " " + this.clockTo, this.creator.username, event.id, event.appointmentType, event.allDay)
          let foundIndex = this.events.findIndex(appointment => event.id === appointment.id)
          if (foundIndex !== -1) {
            this.events[foundIndex].name = this.creator.username
            this.events[foundIndex].start = event.start.substr(0, 10) + " " + this.clockFrom
            this.events[foundIndex].end = event.end.substr(0, 10) + " " + this.clockTo
            this.events[foundIndex].color = "accent"
          }
          this.creator = undefined
          this.nameEditDialog = false
        } else {
          this.updateAppointment(event.start.substr(0, 10) + " " + this.clockFrom, event.end.substr(0, 10) + " " + this.clockTo, event.name, event.id, event.appointmentType, event.allDay)
          let foundIndex = this.events.findIndex(appointment => event.id === appointment.id)
          if (foundIndex !== -1) {
            this.events[foundIndex].start = event.start.substr(0, 10) + " " + this.clockFrom
            this.events[foundIndex].end = event.end.substr(0, 10) + " " + this.clockTo
          }
          this.creator = undefined
          this.nameEditDialog = false
        }
      } else {
        if (this.creator !== undefined) {
          this.updateAppointment(this.dateFrom1 + " " + "12:00", this.dateTo1 + " " + "12:00", this.creator.username, event.id, event.appointmentType, event.allDay)
          let foundIndex = this.events.findIndex(appointment => event.id === appointment.id)
          if (foundIndex !== -1) {
            this.events[foundIndex].name = this.creator.username
            this.events[foundIndex].start = this.dateFrom1
            this.events[foundIndex].end = this.dateTo1
            this.events[foundIndex].color = "accent"
          }
          this.creator = undefined
          this.nameEditDialog = false
        } else {
          this.updateAppointment(this.dateFrom1 + " " + "12:00", this.dateTo1 + " " + "12:00", event.name, event.id, event.appointmentType, true)
          let foundIndex = this.events.findIndex(appointment => event.id === appointment.id)
          if (foundIndex !== -1) {
            this.events[foundIndex].start = this.dateFrom1
            this.events[foundIndex].end = this.dateTo1
          }
          this.creator = undefined
          this.nameEditDialog = false
        }
      }

    },
    openEditDialog() {
      this.nameEditDialog = true
      this.clockFrom = this.selectedEvent.start.substr(11, 5)
      this.clockTo = this.selectedEvent.end.substr(11, 5)
      this.dateFrom1 = this.selectedEvent.start.substr(0, 10)
      this.dateTo1 = this.selectedEvent.end.substr(0, 10)

    },
    async absentSave() {
      await this.createAppointment(this.dateFrom + " 12:00", this.dateTo + " 12:00", this.admin ? "Zu benennen" : this.$RStore.user.Username, 'absent', true)
      const event = {
        "allDay": true,
        "start": this.dateFrom,
        "end": this.dateTo,
        "name": this.admin ? "Zu benennen" : this.$RStore.user.Username,
        "appointmentType": this.admin ? 'callback' : 'absent',
        "color": this.admin ? "error" : "primary",
        "id": this.createEvent.id
      };
      this.events.push(event)
      this.absentDialog = false
    },
  },
  computed: {
    getPermission() {
      if (this.admin) {
        return true
      }
      return reactiveStorage.user.permissions.MPU.MPU
    },
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    inputLables() {
      return JSON.parse(JSON.stringify(this.$t('export.admin.inputField')));
    },
  },
  watch: {
    searchCreator() {
      // Items have already been loaded
      if (this.creators.length > 0) return

      // Items have already been requested
      if (this.isLoadingCreator) return

      this.isLoadingCreator = true

      // Lazily load input items
      this.getAllCreators();

    },
  },
}
</script>

<style lang="scss" scoped>
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
