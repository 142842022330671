import { render, staticRenderFns } from "./LinkEventWithCase.vue?vue&type=template&id=22c8a0e2&scoped=true"
import script from "./LinkEventWithCase.vue?vue&type=script&lang=js"
export * from "./LinkEventWithCase.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c8a0e2",
  null
  
)

export default component.exports