<template>
  <v-card-actions v-if="trueIfAppointmentIsNotFull">
    <link-event-with-case v-if="!this.$route.query.caseId" v-bind:event="selectedEvent"/>
    <v-btn v-if="this.$route.query.caseId && mpu" color="blue" @click="link(selectedEvent.id)">
      <v-icon>mdi-link</v-icon>
      <span>Verknüpfen</span>
    </v-btn>
  </v-card-actions>
</template>

<script>
import LinkEventWithCase from "@/components/dialogs/event/LinkEventWithCase";
import {sessionHandler} from "@/request/sessionHandler";
import {getMpuURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "AppointmentLinkingActions",
  props: {
    selectedEvent: {
      required: true
    },
    mpu: {
      required: true
    }
  },
  data: () => ({
    cases: [],
  }),
  components: {LinkEventWithCase},
  methods: {
    async link(eventId) {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .patch(
              getMpuURL() + "/" + this.$route.query.caseId + "/Appointment/" + eventId
              , {}, {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$router.back();
            } else {
              showAlert(i18n.t('error.api.undefined') + "LinkEventWithCase-gOC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "LinkEventWithCase-gOC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCases = false;
          })
    },
    diff_hours(dt2, dt1) {
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= (60 * 60);
      return Math.abs(Math.round(diff));
    },
    async getCases(){
      this.isLoading = true
      let parameter = {
        'appointmentId': this.selectedEvent.id,
      }
      await sessionHandler();
      await this.$http
          .get(
              getMpuURL(),
              {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.cases = response.data
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getOpenCases-1-admin=" + this.admin, "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getOpenCases-2-admin=" + this.admin, "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
  },
  computed: {
    trueIfAppointmentIsNotFull() {
      const appointmentDuration = this.diff_hours(new Date(this.selectedEvent.end), new Date(this.selectedEvent.start));
      return appointmentDuration - this.cases.length;
    }
  },
  mounted() {
    this.getCases();
  },
  watch: {
    selectedEvent: function () {
      this.getCases();
    }
  }
}
</script>

<style scoped>

</style>
